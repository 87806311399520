<template>
  <OrderApprovedWrapper :method="method" :data="data">
    <template #boleto>
      <Boleto :data="data" />
    </template>
    <template #pix>
      <Pix :data="data" />
    </template>
    <template #none>
      <Pix />
    </template>
  </OrderApprovedWrapper>
</template>

<script>
import Boleto from "./OrderTypes/Boleto.vue";
import Pix from "./OrderTypes/Pix.vue";
import OrderApprovedWrapper from "./OrderApprovedWrapper.vue";
import { mapActions } from "vuex";

export default {
  components: { OrderApprovedWrapper, Boleto, Pix },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["removeToCart"]),

  },

  destroyed() {
    const cart = localStorage.getItem("cart");

    const cartObject = JSON.parse(cart);

    cartObject.cartItems.forEach((item) => {
      this.removeToCart(item);
    });
  },

  computed: {
    method() {
      const method = this.$route.query.method || "none";
      return method;
    },
    data() {
      return this.$route.params.data || "none";
    }


  },
};
</script>
